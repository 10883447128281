<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('sidebar.eventPublication')}`"
      :backButton="true"
      @backToPage="moveToOther('Event Publication')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />

          <Textarea
            v-model="edited_item.location"
            :label="$t('eventPublication.googleMapLink')"
            class="s-mb-20"
            :hint="$t('eventPublication.googleMapLinkHint')"
            :error="validation.location"
            placeholder="<iframe src='...'></iframe>"
          />

          <Input
            v-model="edited_item.location_name"
            :label="$t('eventPublication.locationName')"
            class="s-mb-20"
            :error="validation.location_name"
          />

          <Input
            :label="$t('general.date')"
            type="date"
            v-model="edited_item.date"
            class="s-mb-20"
            :error="validation.date"
          />
          <Input
            :label="$t('general.time')"
            type="time"
            v-model="edited_item.time"
            :error="validation.time"
            class="s-mb-20"
          />

          <div class="s-position-relative">
            <div class="s-mb-20">
              <label class="s-input-label">{{ $t("APMA.cyclePhase")}}  ({{$t('general.optional')}}) </label>

              <Multiselect
                v-model="edited_item.cyclephase"
                :placeholder="`${$t('general.searchOrAdd')} ${$t('APMA.cyclePhase')}`"
                label="name"
                :options="cyclephase_dropdown.map((type) => type.id)"
                :custom-label="
                  (opt) => cyclephase_dropdown.find((x) => x.id == opt).name
                "
              ></Multiselect>
            </div>
          </div>

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.description')"
            class="s-mb-20"
            :error="validation.description"
          />

          <Textarea
            v-model="edited_item.event_link"
            :label="$t('eventPublication.eventLink')"
            class="s-mb-20"
            :error="validation.event_link"
          />
          <p class="s-text-primary s-weight-600">
            {{ $t("general.uploadImage") }}
          </p>
          <div v-if="inputType == 'Edit'">
            <img
              :src="event_item.image"
              alt=""
              style="width: 100px; height: 100px; object-fit: contain"
            />
          </div>

          <div class="s-pb-30 s-position-relative">
            <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
              <div>
                <img
                  :src="require('@/assets/icons/primary/cloud_upload.png')"
                />
                <p>{{ $t("general.dragAndDrop") }}</p>
                <p>{{ $t("general.or") }}</p>
                <Button>{{ $t("general.uploadFile") }}</Button>
              </div>
            </div>
            <input
              v-if="!fileDoc.name"
              id="document"
              ref="document"
              class="s-hide"
              type="file"
              @change="importData('document')"
              multiple
              accept="image/png, image/jpg, image/jpeg"
            />
            <div class="s-position-relative s-fullWidth s-center-flex">
              <div
                class="
                  s-center-flex
                  s-mb-16
                  s-bg-green-40
                  s-plr-10
                  s-radius-10
                  s-fullWidth
                "
                v-if="fileDoc.name"
              >
                <p class="s-text-primary">{{ fileDoc.name }}</p>
                <img
                  :src="require('@/assets/icons/close-primary.svg')"
                  class="s-ml-auto"
                  @click="fileDoc = {}"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import Textarea from "../helper/Textarea.vue";

export default {
  components: { ContentTemplate, Textarea },
  props: ["inputType"],
  mixins: [form],
  data() {
    return {
      isLoading: false,
      fileDoc: {},
      edited_item: {
        title: "",
        location: "",
        location_name: "",
        date: "",
        time: "",
        cyclephase: "",
        event_link: "",
        description: "",
      },
      validation: {
        title: false,
        location: false,
        location_name: false,
        date: false,
        time: false,
        // cyclephase: false,
        event_link: false,
        description: false,
      },
      //empty_field: false,
    };
  },

  computed: {
    ...mapGetters({
      cyclephase_dropdown: "cyclephase/getCyclePhase",
      event_item: "event/getEvent",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },

  mounted() {
    this.initData();
    this.getEditedData();
  },
  methods: {
    ...mapActions({
      event: "event/postEventData",
      setCyclePhase: "cyclephase/setCyclePhaseData",
      setEventSingleData: "event/setEventSingleData",
      event_update: "event/updateEventData",
    }),
    async initData() {
      this.setLoadingPage(true)
      await this.setCyclePhase();
      this.setLoadingPage(false)
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },

    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setEventSingleData(this.$route.params.id);
        this.edited_item.title = this.event_item.title;
        this.edited_item.location = this.event_item.location;
        this.edited_item.location_name = this.event_item.location_name;
        this.edited_item.date = this.event_item.date;
        this.edited_item.time = this.event_item.time;
        this.edited_item.description = this.event_item.description;
        this.edited_item.event_link = this.event_item.event_link;
        // this.edited_item = Object.assign({}, this.event_item);
        this.edited_item.cyclephase = this.event_item.cycle_phase_id;
      }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        //this.edited_item.expertise.forEach(extractIdExpertise);
        let formData = new FormData();
        formData.append("title", this.edited_item.title);
        formData.append("date", this.edited_item.date);
        formData.append("time", this.edited_item.time);
        formData.append("location", this.edited_item.location);
        formData.append("location_name", this.edited_item.location_name);
        formData.append("event_link", this.edited_item.event_link);
        formData.append("cycle_phase_id", this.edited_item.cyclephase);
        formData.append("description", this.edited_item.description);
        formData.append("image", this.fileDoc);

        if (this.inputType == "New") {
          await this.event(formData);
          if (this.$store.getters["event/getStatus"] == 1) {
            this.$router.push(this.$translate({ name: "Event Publication" }));
          } else {
            this.showSnackbar({
              type: "error",
              text: this.$t("general.addOrEditData"),
            });
          }
        } else {
          formData.append("id", this.$route.params.id);
          await this.event_update(formData);
          if (this.$store.getters["event/getStatus"] == 1) {
            this.$router.push(this.$translate({ name: "Event Publication" }));
          } else {
            this.showSnackbar({
              type: "error",
              text: this.$t("general.addOrEditData"),
            });
          }
        }
        // this.cancelForm();

        this.isLoading = false;
      } else {
        window.scrollTo(0, 0);
      }
    },

    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Event Publication",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
